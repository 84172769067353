@font-face {
  font-family: 'f1-bold';
  src: url('/_metronic/assets/fonts/f1-display-bold.ttf');
}

@font-face {
  font-family: 'f1-regular';
  src: url('/_metronic/assets/fonts/f1-display-regular.ttf');
}

.ff-f1 {
  &-regular {
    font-family: 'f1-regular';
  }

  &-bold {
    font-family: 'f1-bold';
  }
}
