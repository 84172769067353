.cardContainer {
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
  display: inline-block;
}

.retailPageContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
  padding: 50px 9%;
}

.retailPageHeader {
  display: flex;
  justify-content: space-between; 
  align-items: center;
  padding-top: 30px;
  padding: 50px 9%;
}

.retailCarousel .slick-slide {
  margin-right: 25px;
  display: flex;
  flex: 1;
}

.retailProductCard {
  width: 340px;
  background-color: #fff;
  border-radius: 0.25rem;
  overflow: hidden;
  margin-right: 40px;
  margin-bottom: 40px;
  height: 380px;
}
.retailProductCardImg {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.cardContainer img {
  display: block;
  width: 100%;
  height: 230px;
}

.emptyImg {
  display: block;
  width: 100%;
  height: 230px;
}

//WIZARD
.retailWizardContainer {
  max-width: 60vw !important;
  margin: auto;
}

.cardImage {
  display: inline-block;
}

.cardBody {
  flex-direction: column;
  height: 100%;
  padding: 1rem;
}

.cardTitle {
  font-size: 1.25rem;
  color: black;
}

.cardText {
  font-size: 1rem;
  margin-bottom: 1rem;
  color: black;
  display: block;
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  max-height: 3.6em;
  line-height: 1.8em;
  height: 50px;
}

.retailBookNowBtn {
  color: white;
  margin-right: 0.5rem;
}

.slider-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Overrides the default color of the dots */
.slick-dots li button:before {
  padding-top: 20px;
  color: white !important;
}

.slick-dots li.slick-active button:before {
  padding-top: 20px;
  color: white !important;
}

:global {
  .slick-dots li button:before {
    padding-top: 20px;
    color: white !important;
  }

  .slick-dots li.slick-active button:before {
    padding-top: 20px;
    color: white !important;
  }
}

.cardContainer {
  display: flex;
  flex-direction: row;
  justify-content:left;
  align-items: center;
  margin-left: 150px;
  padding-bottom: 50px;
}

.seat-booking-container > div {
  height: 450px !important;
}